import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const GamePreview = ({gridItems}) => (
    <div className="columns is-multiline">
        {gridItems.map((item) => (
            <div key={item.text} className="column is-12">
                <section className="section">
                    <a href={item.link} rel="nofollow">
                        <div className="has-text-centered">
                            <div
                                style={{
                                    width: '240px',
                                    display: 'inline-block',
                                }}
                            >
                                <PreviewCompatibleImage imageInfo={item}/>
                            </div>
                        </div>
                    </a>
                    <h4>{item.title}</h4>
                    <p className="justify-center">{item.text}</p>
                    <div className="is-12" style={{display: "flex", justifyContent: "flex-end"}}>
                        <a className="btn-light pr-4" href={item.bloglink}>
                            {
                                item.bloglink === null ?
                                    "Artikel wird bearbeitet"
                                    : "Artikel lesen"
                            }
                        </a>
                        <a className="btn" href={item.link} rel="nofollow">Auf Amazon ansehen*</a>
                    </div>
                </section>
            </div>
        ))}
    </div>
)

GamePreview.propTypes = {
    gridItems: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            text: PropTypes.string,
            alt: PropTypes.string,
            title: PropTypes.string,
            link: PropTypes.string,
            bloglink: PropTypes.string
        })
    ),
}

export default GamePreview
