import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import GamePreview from "../components/GamePreview";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const IndexPageTemplate = ({
                                      image,
                                      title,
                                      heading,
                                      subheading,
                                      mainpitch,
                                      explanation,
                                      description,
                                      intro,
                                  }) => {
  const heroImage = getImage(image) || image;
  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container" style={{maxWidth: "1180px"}}>
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h2 className="title">{mainpitch.title}</h2>
                    </div>
                    <div className="tile">
                      <p className=" justify-center">{mainpitch.description}</p>
                    </div>
                    <div className="tile">
                      <p className="justify-center mt-4">{mainpitch.secondDesc}</p>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-4">
                        {explanation.title}
                      </h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-8">
                      <p className="justify-center">{explanation.description1}</p>
                      <p className="justify-center">{explanation.description2}</p>
                    </div>
                    <div className="column is-4">
                      <img src="/img/spiele/spiele-zu-zweit-erwachsene.jpg"
                           alt="Spiele zu Zweit für erwachsene bilder"/>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <p>{description}</p>
                    </div>
                  </div>
                  <GamePreview gridItems={intro.blurbs}/>
                  {/*<div className="columns">
                                    <div className="column is-12 has-text-centered">
                                        <Link className="btn" to="#">
                                            Hier geht es bald zu allen Spielen
                                        </Link>
                                    </div>
                                </div>*/}
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Mehr im Brettspiele Blog
                    </h3>
                    <BlogRoll/>
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog/">
                        Mehr im Spiele zu zweit Blog
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    explanation: PropTypes.object,
    description: PropTypes.string,
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
                intro={frontmatter.intro}
                explanation={frontmatter.explanation}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        subheading
        mainpitch {
          title
          description
          secondDesc
        }
        explanation {
          title
          description1
          description2
        }
        heading
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            alt
            title
            text
            link
            bloglink
          }
          heading
          description
        }
      }
    }
  }
`;
